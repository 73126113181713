
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon
  },
  emits: [
    "close",
    "clickFileInputRef",
    "exportAllStudents",
    "exportGoogleTemplate",
    "exportMicrosoftTemplate",
    "startGoogleUpdating",
    "startMicrosoftUpdating"
  ],
  props: {
    type: String
  }
})
export default class MsCsvUUpdateModal extends Vue {
  type: "googleLinkage" | "microsoftLinkage" | "studentsUpdate" | "" = "";

  get title() {
    if (this.type === "studentsUpdate") {
      return "生徒のCSV取り込み";
    } else if (this.type === "googleLinkage") {
      return "Googleアカウント連携";
    } else if (this.type === "microsoftLinkage") {
      return "Microsoftアカウント連携";
    }
    return "";
  }

  startUpdating() {
    this.$emit("clickFileInputRef");
  }

  exportStudents() {
    this.$emit("exportAllStudents");
  }

  exportGoogleTemplate() {
    this.$emit("exportGoogleTemplate");
  }

  startGoogleUpdating() {
    this.$emit("startGoogleUpdating");
  }

  startMicrosoftUpdating() {
    this.$emit("startMicrosoftUpdating");
  }

  exportMicrosoftTemplate() {
    this.$emit("exportMicrosoftTemplate");
  }

  close() {
    this.$emit("close");
  }
}
