
import { getCsvText } from "@/api/csv";
import {
  fetchStudentConfig,
  moveUpGradeOfStudents,
  moveDownGradeOfStudents,
  deleteStudent,
  updateStudentRecess,
  getStudentsBasedOnRole,
  registerStudent
} from "@/api/student";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { TabItem } from "@/components/MHeaderPanel.vue";
import MTextField from "@/components/form/MTextField.vue";
import MsMultiRunDropDown from "@/components/student/MsMultiRunDropDown.vue";
import MSAddModal from "@/components/student/MSAddModal.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import MsAddGroupModal from "@/components/student/MsAddGroupModal.vue";
import MsReserveModal from "@/components/student/MsReserveModal.vue";
import MsCsvUpdateModal from "@/components/student/MsCsvUpdateModal.vue";
import { Classroom, classroomCollectionKey } from "@/entities/classroom";
import {
  convertToStudentConfig,
  Student,
  StudentConfig,
  studentConfigCollectionKey,
  StudentGrade
} from "@/entities/student";
import store, { LoginInfo } from "@/store";
import { Options, Vue } from "vue-class-component";
import { School, schoolCollectionKey } from "@/entities/school";
import { CustomProperty } from "@/entities/custom_property";
import { Group } from "@/entities/group";
import { RunningRegistrant } from "@/entities/room";
import { RunningTimer } from "@/entities/learning";
import {
  exportToShiftJisCsv,
  readAsText,
  validateCsvAndConvertToNewStudentDatas
} from "@/utils/csv";
import { Getter, State } from "@/store/helper";
import { saveErrorLog } from "@/api/error";
import {
  studentsTableDefinition,
  TableData,
  formatUnixtime
} from "@/utils/table";
import { formatReflectionsForCsv } from "@/utils/learning_card";
import { getEmail } from "@/api/auth";
import {
  formatTodoDataForCsv,
  getStudentsTodos,
  SuccessfulResult
} from "@/api/todo";
import { getStudentsLearningCardInfo } from "@/api/learning_card";
import firebase from "firebase/app";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import parse from "csv-parse/lib/sync";
import { getSchool } from "@/api/school";
import { NewStudentData } from "../api/student";
import { getClassRoomsBasedOnRole } from "../api/classroom";
import { sortClassroomsById } from "../utils/classroom";
import { validEmail } from "@/utils/email";
import { chunkArray } from "@/utils/array";

function isMatchGradesStudent(studentGrade: string, grades: string[]) {
  return grades.includes(studentGrade);
}

function isMatchDayOfTheWeekStudent(
  studentWeekdays: string,
  dayOfTheWeek: string[]
) {
  if (studentWeekdays === "なし") {
    return dayOfTheWeek.includes(studentWeekdays);
  }

  const weekdays = studentWeekdays.split(", ");
  const firstChildOfWeekdays = dayOfTheWeek.map(schedule =>
    schedule.substr(0, 1)
  );
  for (const weekday of weekdays) {
    if (firstChildOfWeekdays.includes(weekday)) {
      return true;
    }
  }

  return false;
}

async function getStudentConfigOf(
  id: string,
  students: Student[]
): Promise<StudentConfig | null> {
  const matchStudents = students.filter(s => s.ref.id === id);
  if (matchStudents.length === 0) {
    return null;
  }
  const student = matchStudents[0];
  return await fetchStudentConfig(
    student.ref.parent
      .parent!.collection(studentConfigCollectionKey)
      .doc(student.ref.id)
  );
}

type CheckboxData = {
  id: string;
  value: string;
};
type CheckboxDataForColumnList = {
  id: number;
  value: string;
};

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MTableConditionItem,
    MNavBar,
    MHeaderPanel,
    MTextField,
    MsMultiRunDropDown,
    MSAddModal,
    MsSendModal,
    MsAddGroupModal,
    MsReserveModal,
    MsCsvUpdateModal
  },
  watch: {
    learningTerm() {
      if (
        this.learningTerm === "過去7日間" ||
        this.learningTerm === "過去4週間"
      ) {
        this.getLatestData();
      }
    },
    selectedColumnList() {
      const unselectedColumnList = this.columnList
        .filter(
          (column: CheckboxDataForColumnList) =>
            !this.selectedColumnList.includes(column.id)
        )
        .map((column: CheckboxDataForColumnList) => column.id);
      const personalSettingsOfLocalStorage = localStorage[this.email]
        ? {
            ...JSON.parse(localStorage[this.email]),
            unselectedColumnList: unselectedColumnList
          }
        : {
            unselectedColumnList: unselectedColumnList
          };
      localStorage[this.email] = JSON.stringify(personalSettingsOfLocalStorage);
    }
  }
})
export default class StudentList extends Vue {
  tabs: TabItem[] = [
    {
      text: "生徒一覧",
      link: "/student"
    },
    {
      text: "グループ管理",
      link: "/group"
    },
    {
      text: "メッセージ予約",
      link: "/message"
    }
  ];
  currentTabIndex = 0;
  isDisplayedAddStudentModal = false;
  isDisplayedSendModal = false;
  isDisplayedAddGroupModal = false;
  isDisplayedReservationModal = false;
  isCsvUpdateModalOpen = false;
  isGoogleLinkageModalOpen = false;
  isMicrosoftLinkageModalOpen = false;
  withDefaultStudents = false;
  searchKeyword = "";
  isDisplayedSchoolModal = false;
  selectedSchoolIds: string[] = [];
  isDisplayedFilterModal = false;
  selectedClassroomIds: string[] = [];
  grades: CheckboxData[] = [
    {
      id: "elementary1",
      value: "小1"
    },
    {
      id: "elementary2",
      value: "小2"
    },
    {
      id: "elementary3",
      value: "小3"
    },
    {
      id: "elementary4",
      value: "小4"
    },
    {
      id: "elementary5",
      value: "小5"
    },
    {
      id: "elementary6",
      value: "小6"
    },
    {
      id: "middle1",
      value: "中1"
    },
    {
      id: "middle2",
      value: "中2"
    },
    {
      id: "middle3",
      value: "中3"
    },
    {
      id: "high1",
      value: "高1"
    },
    {
      id: "high2",
      value: "高2"
    },
    {
      id: "high3",
      value: "高3"
    },
    {
      id: "other",
      value: "その他"
    }
  ];
  selectedGrades: string[] = [];
  dayOfTheWeeks: CheckboxData[] = [
    {
      id: "monday",
      value: "月曜"
    },
    {
      id: "tuesday",
      value: "火曜"
    },
    {
      id: "wednesday",
      value: "水曜"
    },
    {
      id: "thursday",
      value: "木曜"
    },
    {
      id: "friday",
      value: "金曜"
    },
    {
      id: "saturday",
      value: "土曜"
    },
    {
      id: "sunday",
      value: "日曜"
    },
    {
      id: "none",
      value: "なし"
    }
  ];
  selectedDayOfTheWeek: string[] = [];
  learningTerms: CheckboxData[] = [
    {
      id: "days",
      value: "過去7日間"
    },
    {
      id: "weeks",
      value: "過去4週間"
    }
  ];
  selectedColumnList: number[] = [];
  selectedIds: string[] = [];
  datas: TableData[] = [];
  email = "";
  loading = false;
  isMConditionModalShowing = false;
  modifyStudentListShowing = false;

  @State("baseDatas", "studentList") baseDatas!: {
    [key: string]: string | number | boolean;
  }[];
  @State("beforeLoad", "studentList") beforeLoad!: boolean;
  @State("learningTerm", "studentList") learningTerm!: string;
  @State("termStart", "studentList") termStart!: string;
  @State("termStartTime", "studentList") termStartTime!: number;
  @State("termEnd", "studentList") termEnd!: string;
  @State("termEndTime", "studentList") termEndTime!: number;
  @State("runningRoomRegistrants", "studentList")
  runningRoomRegistrants!: RunningRegistrant[];
  @State("runningTimers", "studentList") runningTimers!: RunningTimer[];

  @State("schools") schools!: School[];
  @State("school") school?: School;
  @State("schoolsForServieProvider") schoolsForServieProvider?: School[];
  @State("classrooms") classrooms!: Classroom[];
  @State("students") students!: Student[];
  @State("groups") groups!: Group[];
  @State("customProperties") customProperties!: CustomProperty[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;
  @Getter("isExclusiveSchoolAiUser") isExclusiveSchoolAiUser!: boolean;
  @Getter("isServiceProvider") isServiceProvider?: boolean;

  changeLearningTerm(term: string) {
    store.commit("studentList/SET_LEARNING_TERM", term);
  }

  changeTermStart(start: string) {
    store.commit("studentList/SET_TERM_START", start);
  }

  changeTermEnd(end: string) {
    store.commit("studentList/SET_TERM_END", end);
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (!this.filterIsSelected) return [];
    let filteredDatas = this.datas;

    if (this.selectedSchoolIds.length > 0) {
      filteredDatas = filteredDatas.filter(data =>
        this.selectedSchoolIds.includes(
          (data["pre-name"] as string).split(".")[0]
        )
      );
    }

    if (this.selectedClassroomIds.length > 0) {
      filteredDatas = filteredDatas.filter(data =>
        this.selectedClassroomIds.includes(
          (data["pre-name"] as string).split(".")[1]
        )
      );
    }

    if (this.selectedGrades.length > 0) {
      filteredDatas = filteredDatas.filter(data =>
        isMatchGradesStudent(data.grade as string, this.selectedGrades)
      );
    }

    if (this.selectedDayOfTheWeek.length > 0) {
      filteredDatas = filteredDatas.filter(data =>
        isMatchDayOfTheWeekStudent(
          data.weekdays as string,
          this.selectedDayOfTheWeek
        )
      );
    }

    if (this.searchKeyword.length > 0) {
      filteredDatas = filteredDatas.filter(data =>
        data.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
      );
    }

    const mySchoolId: string | null = this.school?.ref.id ?? null;
    filteredDatas = filteredDatas
      .map(data => {
        data.realtime = "";
        if (this.runningRoomRegistrants.some(r => r.studentDocId === data.id))
          data.realtime = "🏠";
        if (this.runningTimers.some(r => r.studentDocId === data.id))
          data.realtime = "⏰";
        return data;
      })
      .map(data => {
        data["pre-latestEntranceTimeLabel"] = +data.latestEntranceTime;
        data.latestEntranceTimeLabel = data.latestEntranceTime
          ? `${formatUnixtime(+data.latestEntranceTime)}`
          : "";
        data[
          "pre-totalLatestEntranceTimeLabel"
        ] = +data.totalLatestEntranceTime;
        data.totalLatestEntranceTimeLabel = data.totalLatestEntranceTime
          ? `${formatUnixtime(+data.totalLatestEntranceTime)}`
          : "";
        return data;
      })
      .sort((a, b) => {
        if (mySchoolId) {
          if (a.schoolDocId === mySchoolId && b.schoolDocId !== mySchoolId)
            return -1;
          if (a.schoolDocId !== mySchoolId && b.schoolDocId === mySchoolId)
            return 1;
        }

        return (
          (a.schoolDocId as string).localeCompare(b.schoolDocId as string) ||
          (a["suf-name"] as string).localeCompare(b["suf-name"] as string)
        );
      });

    return filteredDatas;
  }

  get filterIsSelected(): boolean {
    return (
      this.selectedGrades.length > 0 ||
      this.selectedDayOfTheWeek.length > 0 ||
      this.selectedClassroomIds.length > 0
    );
  }

  get definitions() {
    const _originalInfoDefinitions = this.customProperties.map(item => {
      const { title, type } = item.data;
      return {
        text: title,
        key: item.id,
        type: type === "select" ? "string" : type,
        subType: "original",
        prefix: false,
        prefixType: "string",
        suffix: false,
        suffixType: "none",
        sort: "normal",
        size: 28,
        maxSize: "xs",
        showOnMobile: false
      };
    });
    return [...studentsTableDefinition, ..._originalInfoDefinitions];
  }

  get columnList() {
    const _columnList: CheckboxDataForColumnList[] = studentsTableDefinition.map(
      (value, index) => ({
        id: index,
        value: value.text
      })
    );
    const _originalInfoColumnList: CheckboxDataForColumnList[] = this.customProperties.map(
      (item, i) => {
        const { title } = item.data;
        return { id: _columnList.length + i, value: title };
      }
    );
    return [..._columnList, ..._originalInfoColumnList];
  }

  get selectedStudents(): Student[] {
    const selectedStudents = [] as Student[];
    this.selectedIds.forEach(id => {
      const student = this.students.find(student => student.ref.id === id);
      student && selectedStudents.push(student);
    });
    return selectedStudents;
  }

  get classRooms() {
    const classRooms = getClassRoomsBasedOnRole(
      this.isServiceProvider ?? false,
      this.classrooms,
      store.state.school!.ref.id
    );
    return sortClassroomsById(classRooms);
  }

  get dynamicClass(): string {
    const classes: string[] = [];
    if (this.isMConditionModalShowing) {
      classes.push("z-30");
    }
    return classes.join(" ");
  }

  selectAllColumn() {
    this.selectedColumnList = [...Array(this.columnList.length)].map(
      (_, i) => i
    );
  }

  deselectAllColumn() {
    this.selectedColumnList = [];
  }

  selectAllFilter() {
    this.selectAllClassrooms();
    this.selectAllgrades();
    this.selectAlldayOfTheWeeks();
  }

  deselectAllFilter() {
    this.deselectAllClassrooms();
    this.deselectAllgrades();
    this.deselectAlldayOfTheWeeks();
  }

  selectAllClassrooms() {
    this.selectedClassroomIds = this.classrooms.map(item => item.ref.id);
  }

  selectAllgrades() {
    this.selectedGrades = this.grades.map(item => item.value);
  }

  selectAlldayOfTheWeeks() {
    this.selectedDayOfTheWeek = this.dayOfTheWeeks.map(item => item.value);
  }

  deselectAllClassrooms() {
    this.selectedClassroomIds = [];
  }

  deselectAllgrades() {
    this.selectedGrades = [];
  }

  deselectAlldayOfTheWeeks() {
    this.selectedDayOfTheWeek = [];
  }

  closeAddModal() {
    this.isDisplayedAddStudentModal = false;
  }

  showAddModal() {
    this.isDisplayedAddStudentModal = true;
  }

  closeSendModal() {
    this.isDisplayedSendModal = false;
  }

  showSendModal() {
    this.withDefaultStudents = false;
    this.isDisplayedSendModal = true;
  }

  handleShowModalChange(showModalValue: boolean) {
    this.isMConditionModalShowing = showModalValue;
  }

  showSendModalWithDefaultStudents() {
    this.withDefaultStudents = true;
    this.isDisplayedSendModal = true;
  }

  showReservationModal() {
    this.isDisplayedReservationModal = true;
  }

  closeReservationModal() {
    this.isDisplayedReservationModal = false;
  }

  changeSelectedId(id: string) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(item => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  handleMultiDropDownOpened(type?: string) {
    if (type === "modifyStudentList") {
      this.modifyStudentListShowing = true;
    }
    this.isMConditionModalShowing = false;
  }

  handleMultiDropDownClosed(type?: string) {
    if (type === "modifyStudentList") {
      this.modifyStudentListShowing = false;
    }
  }

  async moveToPrint(
    target: string,
    option: { printAll: boolean } = { printAll: false }
  ) {
    const { printAll } = option;
    const targetIds: string[] = printAll
      ? [
          ...getStudentsBasedOnRole(
            this.isServiceProvider ?? false,
            this.students,
            this.school?.ref.id ?? ""
          ).map(s => s.ref.id)
        ]
      : [...this.selectedIds];

    if (targetIds.length === 0) return;

    store.commit(
      "START_LOADING",
      "ログイン情報取得中(数分かかることがあります)..."
    );

    // 何百という生徒がいる学校があるので、パフォーマンスは重視せず、非同期処理を直列で行う
    const studentConfigs: { config: StudentConfig; schoolId: string }[] = [];
    for (const id of targetIds) {
      const config = await getStudentConfigOf(id, this.students);
      if (!config) {
        alert("ログイン情報の取得に失敗しました");
        store.commit("END_LOADING");
        return;
      }
      const schoolId = config.ref.parent.parent?.parent?.parent?.id;
      if (!schoolId) {
        alert("ログイン情報の取得に失敗しました");
        store.commit("END_LOADING");
        return;
      }
      const school = await getSchool(
        firebase
          .firestore()
          .collection(schoolCollectionKey)
          .doc(schoolId)
      );
      if (!school) {
        alert("ログイン情報の取得に失敗しました");
        store.commit("END_LOADING");
        return;
      }

      studentConfigs.push({ config, schoolId: school.data.id });
    }

    const loginInfos: LoginInfo[] = [];
    for (const i in targetIds) {
      const index = Number(i);
      const matchDatas = this.datas.filter(
        data => data.id === targetIds[index]
      );
      if (matchDatas.length > 0) {
        const data = matchDatas[0];
        const { config, schoolId } = studentConfigs[index]!;
        loginInfos.push({
          schoolName: data.schoolName as string,
          studentName: data.name as string,
          loginId: data["suf-name"] as string,
          password: config.data.password,
          schoolId
        });
      }
    }

    store.commit(
      "SET_SELECTED_LOGIN_INFOS",
      loginInfos.sort((a, b) => a.loginId.localeCompare(b.loginId))
    );

    if (target === "mingaku") {
      await this.$router.push("/student/print/auth");
    } else if (target === "schoolAI") {
      await this.$router.push("/student/print/school_ai");
    } else {
      await this.$router.push("/student/print/auth");
    }

    store.commit("END_LOADING");
  }

  async moveUpGrade() {
    const confirmRes = confirm("全生徒の学年を本当に一つ上げますか？");
    if (!confirmRes) {
      return;
    }
    store.commit("START_LOADING", "処理中...");
    try {
      await moveUpGradeOfStudents(this.students);
    } catch (e) {
      store.commit("END_LOADING");
      console.error(e);
      alert(`全生徒の学年を一括で上げるのに失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to move up grade of students"
      );
      return;
    }
    alert("全生徒の学年が一つ上がりました");
    this.$router.go(0);
  }

  async moveDownGrade() {
    const confirmRes = confirm("全生徒の学年を本当に一つ下げますか？");
    if (!confirmRes) {
      return;
    }
    store.commit("START_LOADING", "処理中...");
    try {
      await moveDownGradeOfStudents(this.students);
    } catch (e) {
      store.commit("END_LOADING");
      console.error(e);
      alert(`全生徒の学年を一括で下げるのに失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to move down grade of students"
      );
      return;
    }
    alert("全生徒の学年が一つ下がりました");
    this.$router.go(0);
  }

  async createSchoolAIUserForStudent(student: Student) {
    if (
      student.data.isNandeUserCreated === true &&
      !("unlinkSchoolAI" in student.data)
    ) {
      // すでに連携済みの場合はSkip
      return { status: "skipped" };
    }
    if (student.data.unlinkSchoolAI === false) {
      // 再連携済みの場合はSkip
      return { status: "skipped" };
    }
    if (student.data.recessTime && student.data.recessTime > 0) {
      // 休憩中の場合はSkip
      return { status: "skipped" };
    }
    try {
      // 学校やクラスルームのデータを取得
      // if (student.data.isNandeUserCreated) return null;
      const studentConfigData = await student.ref.parent
        .parent!.collection(studentConfigCollectionKey)
        .doc(student.ref.id)
        .get();
      const schoolData = await student.ref.parent.parent!.parent.parent!.get();
      const schoolLoginId = schoolData.data()!.id;
      const schoolDocId = student.ref.parent.parent!.parent.parent!.id;
      const classroomDocId = student.ref.parent.parent!.id;
      const studentDocId = student.ref.id;
      const studentDocPath = student.ref.path;
      const name = student.data.name;
      const emailReceivers = student.data.emailReceivers;
      const loginId = schoolLoginId + "-" + student.data.id;
      const password = studentConfigData.data()!.password;
      const subData = {
        isNandeUserCreated: student.data.isNandeUserCreated ?? false,
        uidOfSchoolAI: student.data.nandeUserId ?? "",
        unlinkSchoolAI: student.data.unlinkSchoolAI ?? false
      };
      const userInfo = {
        schoolDocId,
        classroomDocId,
        studentDocId,
        studentDocPath,
        name,
        emailReceivers,
        loginId,
        password,
        ...subData
      };
      const createNandeUser = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("create_nande_user");
      await createNandeUser(userInfo);
      return { status: "created" };
    } catch (e) {
      console.error(`Failed for student: ${student.ref.id}`, e);
      return { status: "failed", error: e };
    }
  }

  async processSchoolAIUserCreation(
    students: Student[],
    actionDescription: {
      confirmMessage: string;
      completionMessage: string;
    }
  ) {
    const confirmRes = confirm(actionDescription.confirmMessage);
    if (!confirmRes) return;

    store.commit("START_LOADING", "処理中...(数分時間がかかる場合があります)");

    try {
      const results = await Promise.allSettled(
        students.map(this.createSchoolAIUserForStudent)
      );

      const summary = results.reduce(
        (acc, result) => {
          if (
            result.status === "fulfilled" &&
            result.value!.status === "created"
          )
            acc.created++;
          else if (
            result.status === "fulfilled" &&
            result.value!.status === "skipped"
          )
            acc.skipped++;
          else acc.failed++;
          return acc;
        },
        { created: 0, skipped: 0, failed: 0 }
      );

      console.log(
        `新しく連携したユーザー数: ${summary.created}\nすでに連携されていたユーザー数: ${summary.skipped}\n連携失敗ユーザー数: ${summary.failed}`
      );
      store.commit("END_LOADING");
      alert(actionDescription.completionMessage);
    } catch (e) {
      store.commit("END_LOADING");
      console.error(e);
      alert(`スクールAI連携に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to link to schoolAI"
      );
    }
    this.$router.go(0);
  }

  async createSchoolAIUserForAllStudent() {
    await this.processSchoolAIUserCreation(this.students, {
      confirmMessage:
        "全生徒をスクールAIに連携しますか？(数分時間がかかる場合があります)",
      completionMessage: "全生徒のスクールAI連携が完了しました"
    });
  }

  async createSchoolAIUserForSelectedStudents() {
    await this.processSchoolAIUserCreation(this.selectedStudents, {
      confirmMessage:
        "選択した生徒をスクールAIに連携しますか？(数分時間がかかる場合があります)",
      completionMessage: "選択した生徒のスクールAI連携が完了しました"
    });
  }

  startMultiStudentRegistration() {
    if (
      !this.$refs.fileInputForRegistration ||
      !(this.$refs.fileInputForRegistration instanceof HTMLInputElement)
    ) {
      alert("問題が発生しました。時間をおいて再度実行してください。");
      return;
    }

    this.$refs.fileInputForRegistration.click();
  }

  async registerStudentsViaCsv(event: Event) {
    const MAX = 300;
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let datas: NewStudentData[];
    const students: Student[] = getStudentsBasedOnRole(
      this.isServiceProvider ?? false,
      store.state.students,
      store.state.school!.ref.id
    );

    try {
      datas = await validateCsvAndConvertToNewStudentDatas(
        event.target.files[0],
        this.classRooms,
        this.customProperties,
        students.map(s => s.data.id)
      );
    } catch (e) {
      alert(e);
      return;
    }
    if (datas.length === 0) return;
    if (datas.length > MAX) {
      throw new Error(`一度に登録できるのは${MAX}人までです。`);
    }
    const loginIds = datas.filter(d => d.loginId !== "").map(_ => _.loginId);
    const studentIds = datas.filter(d => d.id !== "").map(_ => _.id);
    if (loginIds.length > 0) {
      const uniqueElements = new Set(loginIds);
      if (loginIds.length !== uniqueElements.size) {
        alert("ログインIDに重複があります。");
        this.$router.go(0);
        return;
      }
    }
    // 普通はありえない
    if (studentIds.length > 0) {
      const uniqueElements = new Set(studentIds);
      if (studentIds.length !== uniqueElements.size) {
        alert("生徒の登録に失敗しました。");
        this.$router.go(0);
        return;
      }
    }

    store.commit("START_LOADING", "生徒登録中...");

    const failures: { name: string; reason: string }[] = [];
    let successCount = 0;
    try {
      const results = await Promise.allSettled(
        datas.map(async (data, i) => {
          await new Promise(_ => setTimeout(_, i === 1 ? 5000 : 500 * i)); //コールドスタートを考慮する
          return registerStudent(data);
        })
      );
      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          successCount++;
        } else {
          failures.push({
            name: datas[index].name ?? "不明な生徒",
            reason: result.reason
          });
        }
      });
    } catch (e) {
      store.commit("END_LOADING");
      alert(`一部生徒の追加に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register student"
      );
      return;
    }
    store.commit("END_LOADING");
    if (datas.length === failures.length) {
      alert("生徒の登録に失敗しました。");
      return;
    }

    const text = `
成功 : ${successCount}件
失敗 : ${failures.length}件

【失敗内容の詳細】
${failures.map(failure => `${failure.name} : ${failure.reason}`).join("\n")}
`.trim();
    alert(text);

    this.$router.go(0);
  }

  downloadCsvTemplate() {
    this.exportStudent({ onlyTemplate: true, exportAll: false });
  }

  exportCsvTemplateForExternalProviderLinkage(val: string) {
    if (!val) return;
    const headers = [
      "ログインID",
      "ユーザーネーム",
      val === "google" ? "Googleアカウント" : "Microsoftアカウント"
    ];
    const csvData = headers.join(",");
    exportToShiftJisCsv(
      csvData,
      `${val === "google" ? "google" : "microsoft"}連携テンプレート`
    );
  }

  validateAndFormatRecords(
    records: {
      [key: string]: string | number;
    }[]
  ) {
    const loginIds: string[] = [];
    const studentIds: string[] = [];
    for (const record of records) {
      const idKey = Object.keys(record).find(key => key.startsWith("id"));
      if (!idKey)
        throw new Error(
          "生徒のidが取得できません。ファイルのデータが正しいかどうか確認してください"
        );
      record.studentId = record[idKey];
      if (studentIds.includes(record.studentId as string))
        throw new Error(
          `生徒ID${record[idKey]}の生徒が複数存在します。ファイルのデータが正しいかどうか確認してください`
        );
      studentIds.push(record.studentId as string);
      const student = this.datas.find(_ => _.id === record[idKey]);
      if (!student)
        throw new Error(
          `生徒ID${record[idKey]}の生徒情報が取得できません。ファイルのデータが正しいかどうか確認してください`
        );
      record.classroomId = student.classroomDocId as string;
      record.schoolId = student.schoolDocId as string;

      if (record.loginId) {
        const trimmed = String(record.loginId).trim();
        if (loginIds.includes(trimmed)) {
          throw new Error(
            `ログインID--${record.loginId}に重複があります。ファイルのデータを確認してください。`
          );
        }
        loginIds.push(trimmed);
      }
    }

    return records;
  }

  validateAndFormatRecord(record: { [key: string]: string | number }) {
    const fields = ["name", "classroomName", "grade", "password", "loginId"];
    const name: string = record.name ? String(record.name) : "- -";
    fields.forEach(field => {
      if (
        record[field] !== undefined &&
        record[field] !== null &&
        !record[field]
      ) {
        throw new Error(`生徒:${name} の${field}が指定されていません。`);
      }

      switch (field) {
        case "name":
          record.name = String(record.name);
          break;

        case "password":
          if (
            !/^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(record.password as string)
          ) {
            throw new Error(
              `生徒:${name} の生徒でパスワードの形式が正しくありません。パスワードは半角英数字記号8文字以上で入力してください。`
            );
          }
          record.password = (record.password as string).trim();
          break;

        case "loginId":
          if (
            !(
              /^[\x21-\x7E]+$/.test(record.loginId as string) &&
              String(record.loginId).length >= 5
            )
          ) {
            throw new Error(
              `生徒:${name} のログインIDの形式が正しくありません。ログインIDは半角英数字5文字以上で入力してください。`
            );
          }
          record.loginId = (record.loginId as string).trim();
          break;

        case "grade": {
          const grades: StudentGrade[] = [
            "小1",
            "小2",
            "小3",
            "小4",
            "小5",
            "小6",
            "中1",
            "中2",
            "中3",
            "高1",
            "高2",
            "高3",
            "その他"
          ];
          if (typeof record.grade !== "string") {
            throw new Error(`生徒:${name} の学年の入力に誤りがあります。`);
          }
          if (!grades.includes(record.grade as StudentGrade)) {
            throw new Error(
              `生徒:${name} で存在しない学年が入力されています。`
            );
          }
          break;
        }

        default:
          // 教室名やカスタムプロパティはバックエンドでバリデーションする
          break;
      }
    });
    return record;
  }

  translateErrorMessage(message: string): string {
    if (message === "This login id is already used by another user") {
      return `${message}(このログインIDはすでにスクール内で使用されています)`;
    } else if (message === `Only tutors of this school can edit this student`) {
      return `${message}(この生徒の編集をする権限がありません)`;
    } else if (
      message.startsWith(`No classroom is found for the given classroom name`)
    ) {
      return `${message}(存在しない教室名です)`;
    } else if (message === `Custom property value is missing`) {
      return `${message}(オリジナル情報の値が指定されていません)`;
    } else if (
      message.startsWith(`There is an overlap in custom property title`)
    ) {
      return `${message}(登録しようとしているオリジナル情報に重複があります。)`;
    } else if (message.startsWith(`There is no such custom property title`)) {
      return `${message}(存在しないオリジナル情報です)`;
    } else {
      return message;
    }
  }

  startStudentRegistration() {
    this.showAddModal();
  }
  startEditingStudentsViaCsv() {
    this.isCsvUpdateModalOpen = true;
  }
  startExportingAllStudents() {
    this.exportStudent({ exportAll: true, onlyTemplate: false });
  }
  startLinkingGoogle() {
    if (
      !this.$refs.fileInputForGoogleLinkage ||
      !(this.$refs.fileInputForGoogleLinkage instanceof HTMLInputElement)
    ) {
      alert("問題が発生しました。時間をおいて再度実行してください。");
      return;
    }

    this.$refs.fileInputForGoogleLinkage.click();
  }
  openGoogleLinkageModal() {
    this.isGoogleLinkageModalOpen = true;
  }
  openMicrosoftLinkageModal() {
    this.isMicrosoftLinkageModalOpen = true;
  }
  startLinkingMicrosoft() {
    if (
      !this.$refs.fileInputForMicrosoftLinkage ||
      !(this.$refs.fileInputForMicrosoftLinkage instanceof HTMLInputElement)
    ) {
      alert("問題が発生しました。時間をおいて再度実行してください。");
      return;
    }

    this.$refs.fileInputForMicrosoftLinkage.click();
  }

  validateCsvInputForExternalProviderLinkage(datas: string[][]): boolean {
    const emails: string[] = [];
    const loginIds: string[] = [];
    for (const row of datas) {
      row.forEach(data => data.trim());

      const [loginId, userName, email] = row;

      if (!loginId || !userName || !email) {
        alert("空白は許可されていません。");
        return false;
      }

      const _student = this.filteredDatas.find(
        student => student["suf-name"] === loginId
      );
      if (!_student) {
        alert(loginId + "は存在しないログインIDです。");
        return false;
      }

      if (_student.name !== userName) {
        alert(`${loginId}と${userName}が一致しません。`);
        return false;
      }

      if (loginIds.includes(loginId)) {
        alert(loginId + "が複数登録されようとしています。");
        return false;
      }

      if (!validEmail(email)) {
        alert(email + "は有効なメールアドレスではありません。");
        return false;
      }

      if (emails.includes(email)) {
        alert(email + "が複数登録されようとしています。");
        return false;
      }

      loginIds.push(loginId);
      emails.push(email);
    }

    return true;
  }

  isRightFileNameForExternalProviderLinkage(
    fileName: string,
    provider: "google" | "microsoft"
  ) {
    if (provider === "google") {
      return fileName.startsWith("google");
    } else {
      return fileName.startsWith("microsoft");
    }
  }

  async onCsvInputForExternalProviders(
    event: Event,
    provider: "google" | "microsoft"
  ) {
    const MAX = 300;
    if (!this.school) return;

    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    if (!window.confirm(`${provider}に連携しますか？`)) return;

    const file = event.target.files[0];
    if (!file) return;

    if (!this.isRightFileNameForExternalProviderLinkage(file.name, provider)) {
      alert(
        "登録できません。" +
          "\n" +
          `連携の対象が${provider}であることを確認してください。`
      );
      return;
    }

    const text = await readAsText(file, "Shift_JIS");
    if (typeof text !== "string") {
      alert("ファイルの読み込みに失敗しました。");
      return;
    }

    const rows = text
      .split("\n")
      .map(line => line.replace(/\r$/, ""))
      .filter(line => line.trim() !== "");
    const datas = rows.filter((_, i) => i > 0).map(row => row.split(","));
    if (!datas.length) return;
    if (datas.length > MAX) {
      alert(`一度に登録できるのは${MAX}人までです。`);
      return;
    }

    if (!this.validateCsvInputForExternalProviderLinkage(datas)) {
      //この時にインプットされたfileをinput要素からclearする処理があれば尚良い
      return;
    }

    try {
      store.commit("START_LOADING", "更新中...");

      const linkStudentToExternalProvider = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("link_student_to_external_provider");

      const PROMISE_PARALLEL_LIMIT = 5;
      const chunks = chunkArray(datas, PROMISE_PARALLEL_LIMIT);
      const schoolId = this.school.data.id;

      const results: PromiseSettledResult<void>[] = [];
      for (const chunk of chunks) {
        const chunkResults = await Promise.allSettled(
          chunk.map(async data => {
            const res = await linkStudentToExternalProvider({
              provider,
              schoolId,
              studentLoginId: data[0],
              email: data[2]
            });
            if (!res.data.success) throw new Error("Unexpected error occurred");
          })
        );

        results.push(...chunkResults);
      }

      let successCount = 0;
      const failedCases: { name: string; message: string }[] = [];
      results.forEach((result, i) => {
        if (result.status === "fulfilled") {
          successCount++;
        } else {
          failedCases.push({
            name: datas[i][1],
            message: result.reason?.message ?? "Unknown error"
          });
        }
      });
      store.commit("END_LOADING");
      const text = `
成功 : ${successCount}件
失敗 : ${failedCases.length}件

【失敗内容の詳細】
${failedCases
  .map(failure => `${failure.name ?? "不明な生徒"} : ${failure.message}`)
  .join("\n")}
`.trim();
      alert(text);
      this.$router.go(0);
    } catch (e) {
      console.error(e);
      alert("登録に失敗しました" + "\n" + e);
      store.commit("END_LOADING");
    }
  }

  clickFileInputRef() {
    if (
      !this.$refs.fileInput ||
      !(this.$refs.fileInput instanceof HTMLInputElement)
    ) {
      alert("問題が発生しました。時間をおいて再度実行してください。");
      return;
    }

    this.$refs.fileInput.click();
  }

  async onCsvFileInputForUpdate(event: Event) {
    const MAX = 300;
    if (!(event.target instanceof HTMLInputElement)) return;
    if (!event.target.files || event.target.files.length === 0) return;
    const file = event.target.files[0];
    if (!file) return;

    const confirmRes = confirm(
      "教室を変更する場合、変更先の教室の確認権限を持たないスタッフが生徒情報を確認できなくなります。\n\n【注意】\n学習室入室中の生徒の教室を変更すると入室中の学習記録が正常に保存されません。教室変更は生徒が学習室に入室している時間を避けて実施してください。"
    );
    if (!confirmRes) {
      return;
    }

    try {
      store.commit("START_LOADING", "更新中...(このままお待ちください)");
      let text = "";
      try {
        text = await (async (): Promise<any> => {
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.onload = e => {
              if (e.target && e.target.result instanceof ArrayBuffer) {
                const uint8Array = new Uint8Array(e.target.result);
                const decoder = new TextDecoder("shift-jis");
                const csvText = decoder.decode(uint8Array);
                resolve(csvText);
              } else {
                reject(reader.result);
              }
            };
            reader.onerror = e => {
              reject(e);
            };
            reader.readAsArrayBuffer(file);
          });
        })();
      } catch (e) {
        console.error(e);
        throw new Error(`ファイルの読み込みに失敗しました。${e.message ?? ""}`);
      }

      if (typeof text !== "string")
        throw new Error(
          "ファイルを正常に読み込むことができませんでした。CSVファイルのデータが正しいかどうか確認してください"
        );
      if (!text) throw new Error("ファイル内にデータが存在しません。");

      let records: { [key: string]: string | number }[] = parse(text, {
        columns: true,
        skipEmptyLines: true
      });
      if (records.constructor !== Array)
        throw new Error(
          "ファイルを正常に読み込むことができませんでした。CSVファイルのデータが正しいかどうか確認してください"
        );
      if (records.length > MAX)
        throw new Error(`一度に変更できるのは${MAX}人までです。`);

      records = this.validateAndFormatRecords(records);

      const call = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("update_student_via_csv");

      const results = await Promise.allSettled(
        records.map(async (record, i) => {
          const _record = this.validateAndFormatRecord(record);
          await new Promise(_ => setTimeout(_, i === 0 ? 0 : i * 1000 + 8000)); //コールドスタートを考慮
          return call(_record);
        })
      );

      let successCounts = 0;
      const failedCases: { studentId: string; message: string }[] = [];
      results.forEach((result, i) => {
        if (result.status === "fulfilled") {
          successCounts++;
        } else {
          failedCases.push({
            studentId: (records[i].name as string) ?? "unknown name",
            message: result.reason.message ?? "Unknown error"
          });
        }
      });

      alert(
        `更新成功件数 : ${successCounts}件` +
          "\n" +
          `失敗件数 : ${failedCases.length}件` +
          `${
            failedCases.length > 0
              ? "\n\n" +
                failedCases
                  .map(
                    _ =>
                      `${_.studentId}` +
                      "\n" +
                      `${this.translateErrorMessage(_.message)}`
                  )
                  .reduce((acc, cur, i) => {
                    return i === failedCases.length - 1
                      ? acc + cur
                      : acc + (cur + "\n\n");
                  }, "")
              : ""
          } `
      );
    } catch (e) {
      console.error(e);
      alert(`生徒情報の更新に失敗しました。${e.message ?? ""}`);
    } finally {
      if (this.$refs.fileInput) {
        (this.$refs.fileInput as HTMLInputElement).value = "";
      }
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async exportToCsv() {
    const confirmRes = confirm(
      `対象生徒数: ${
        this.selectedIds.length
      }人\n対象期間: ${this.termStart
        .split("-")
        .join("/")} ~ ${this.termEnd
        .split("-")
        .join("/")}\n\n上記の条件の学習データを出力しようとしています`
    );
    if (!confirmRes) {
      return;
    }

    store.commit("START_LOADING", "CSV出力中...");
    try {
      const csvText = await getCsvText(
        this.termStartTime,
        this.termEndTime,
        this.selectedIds
      );
      exportToShiftJisCsv(csvText, "みんがく_学習データ");
    } catch (e) {
      alert(`予期せぬエラーが発生しました\n\n${e.message}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get csv of learnings"
      );
    }

    store.commit("END_LOADING");
  }

  async exportLearningCard() {
    if (!this.selectedStudents || !this.selectedStudents.length) return;
    /*
    複数の深い階層のコレクションを複数生徒で取得するため、バックエンドで処理を1秒ごとに遅延させる部分がある
    そのため、Cloud Functionsのタイムアウトの上限を超えそうな人数が選択された時は処理を行わない
    */
    if (this.selectedStudents.length > 500) {
      alert("500人以上のデータを一度にエクスポートすることはできません。");
      return;
    }
    const selectedStudentsRefs = this.selectedStudents.map(s => s.ref);
    try {
      store.commit("START_LOADING", "CSV出力中（数分かかることがあります）...");
      const { res } = await getStudentsLearningCardInfo(selectedStudentsRefs);
      const { successfulResults, failedResults } = res;

      if (failedResults.length > 0) {
        const message = failedResults.reduce((acc, cur) => {
          const data =
            "\n\n" +
            `生徒ID: ${cur.result.studentDocId ?? "不明なID"}` +
            "\n" +
            `生徒名: ${cur.result.studentName ?? "不明な生徒"} ` +
            "\n" +
            `メッセージ: ${cur.message ?? "不明なエラー"}` +
            "\n\n";
          return acc + data;
        }, "一部の生徒で情報の取得に失敗しました。");

        alert(message);
      }

      const sorted = [...successfulResults].sort((a, b) =>
        a.result.studentDocId.localeCompare(b.result.studentDocId)
      );
      const texts = formatReflectionsForCsv(sorted);
      const { unitsData, reflectionsData } = texts;

      exportToShiftJisCsv(
        unitsData,
        `学習カードデータ(単元)_${dayjs().format("YYYY-MM-DD")}`
      );
      exportToShiftJisCsv(
        reflectionsData,
        `学習カードデータ(振り返り)_${dayjs().format("YYYY-MM-DD")}`
      );
    } catch (e) {
      alert("学習カード情報の取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
    }
  }

  async exportTodoToCsv() {
    if (!this.selectedStudents || !this.selectedStudents.length) return;
    let result: any;
    const selectedStudentsRefs = this.selectedStudents.map(s => s.ref);
    try {
      store.commit("START_LOADING", "CSV出力中...");
      result = await getStudentsTodos(selectedStudentsRefs);
      if (result.status !== "success") {
        throw new Error("Failed to get todos");
      }
    } catch (e) {
      alert("TODO情報の取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
    }

    const rawData = (result.res.successfulResults as SuccessfulResult[]).sort(
      (a, b) => {
        if (a.schoolId < b.schoolId) return -1;
        if (a.schoolId > b.schoolId) return 1;
        if (a.classroomId < b.classroomId) return -1;
        if (a.classroomId > b.classroomId) return 1;
        if (a.studentId < b.studentId) return -1;
        if (a.studentId > b.studentId) return 1;
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }
    );
    const textToExport = formatTodoDataForCsv(rawData);
    exportToShiftJisCsv(
      textToExport,
      `TODOデータ_${dayjs().format("YYYY-MM-DD")}`
    );
  }

  async getStudentsPassword(
    studentConfigRef: firebase.firestore.DocumentReference
  ): Promise<{ uid: string; password: string } | null> {
    const snapshot = await studentConfigRef.get();
    if (!snapshot.exists || !snapshot.data()) return null;
    const configData = convertToStudentConfig(
      snapshot.data() as firebase.firestore.DocumentData,
      snapshot.ref
    );
    const password = configData.data.password;
    return { uid: studentConfigRef.id, password };
  }

  getPropertyValueById(
    student: { [key: string]: any },
    propertyId: string
  ): string | number {
    if (!student) return "";
    if (student[propertyId]) return student[propertyId];
    const properties: { id: string; value: string | number }[] | undefined =
      student.properties;
    if (!properties) return "";
    const matchedProperty = properties.find(p => p.id === propertyId);
    if (matchedProperty === undefined) return "";
    if (matchedProperty.value === undefined) return "";
    return matchedProperty.value;
  }

  async exportStudent(
    option: {
      exportAll: boolean;
      onlyTemplate: boolean;
    } = {
      exportAll: false,
      onlyTemplate: false
    }
  ) {
    if (this.loading) {
      alert("生徒情報がまだ読み込まれていません。");
      return;
    }
    const { exportAll, onlyTemplate } = option;
    if (exportAll && onlyTemplate) return;
    let studentsToExport: Record<string, string | number | boolean>[] = [];
    if (!onlyTemplate) {
      studentsToExport = exportAll
        ? [...this.filteredDatas]
        : [...this.filteredDatas].filter(_ =>
            this.selectedIds.includes(_.id as string)
          );

      if (studentsToExport.length === 0) {
        alert("エクスポートする生徒情報がありません。");
        return;
      }
    } else {
      studentsToExport = [];
    }

    try {
      store.commit("START_LOADING", "情報取得中...");

      const passwords = (await Promise.all(
        studentsToExport
          .map(async (s, i) => {
            const ref = firebase
              .firestore()
              .collection(schoolCollectionKey)
              .doc(s.schoolDocId ? (s.schoolDocId as string) : "")
              .collection(classroomCollectionKey)
              .doc(s.classroomDocId ? (s.classroomDocId as string) : "")
              .collection(studentConfigCollectionKey)
              .doc(s.id ? (s.id as string) : "");
            await new Promise(_ => setTimeout(_, 100 * i));
            return this.getStudentsPassword(ref);
          })
          .filter(_ => _ !== null)
      )) as { uid: string; password: string }[];

      store.commit("END_LOADING");

      const headerForCustomProperties =
        this.customProperties.length > 0
          ? this.customProperties
              .map((_, i) => {
                return [
                  `customProperty${i ? i + 1 : ""}`,
                  `customPropertyValue${i ? i + 1 : ""}`
                ];
              })
              .reduce((acc, cur) => [...acc, ...cur], [])
          : [];
      const commonHeader = [
        "name",
        "classroomName",
        "grade",
        "password",
        "loginId"
      ];
      if (!onlyTemplate) {
        commonHeader.unshift("id ※編集不可");
      }
      const headerData = this.isAdmin
        ? [...commonHeader]
        : [...commonHeader, ...headerForCustomProperties];
      const csvData: string = studentsToExport.reduce((acc: string, cur) => {
        const found = passwords.find(_ => _.uid === (cur.id as string));
        const password: string = found ? found.password : "";
        const row = [];
        row.push(cur.id);
        row.push(cur.name);
        row.push(cur.classroom);
        row.push(cur.grade);
        row.push(password);
        row.push(cur["suf-name"]);
        if (
          !this.isAdmin &&
          this.customProperties &&
          this.customProperties.length > 0
        ) {
          this.customProperties.forEach(c => {
            if (this.isServiceProvider) {
              row.push(c.data.title);
              if (cur.schoolDocId === this.school?.ref.id) {
                row.push(this.getPropertyValueById(cur, c.id));
              } else {
                row.push("!!子スクールの生徒です");
              }
            } else {
              row.push(c.data.title);
              row.push(this.getPropertyValueById(cur, c.id));
            }
          });
        }
        return acc + row.join(",") + "\n";
      }, headerData.join(",") + "\n");
      exportToShiftJisCsv(
        csvData,
        `${
          onlyTemplate
            ? "生徒登録テンプレート"
            : `生徒情報_${dayjs().format("YYYY-MM-DD")}`
        }`
      );
    } catch (e) {
      alert("生徒の情報を取得中にエラーが発生しました。");
      console.error(e);
      store.commit("END_LOADING");
    }
  }

  async updateLearningTerms() {
    store.commit("studentList/SET_LEARNING_TERM", "期間選択");
    await this.getLatestData();
  }

  async getLatestData() {
    this.loading = true;
    await Promise.all([
      store.dispatch("studentList/getStudentListDatas"),
      store.dispatch("studentList/getStatusList", {
        type: store.state.role?.data.type === "admin" ? "admin" : "tutor",
        tutorDocId:
          store.state.role?.data.type === "admin"
            ? ""
            : store.state.tutor?.main.ref.id
      })
    ]);
    this.datas = this.expandCustomProperties(this.baseDatas);
    this.loading = false;
  }

  async setStudentsRecess() {
    try {
      store.commit("START_LOADING", "更新中...");
      await Promise.all(
        this.selectedIds
          .map(id => {
            const student = this.students.find(
              student => student.ref.id === id
            );
            // 休会だった場合離脱
            if (!student || student.data.recessTime) return;
            return updateStudentRecess(
              student.ref,
              Math.floor(Date.now() / 1000)
            );
          })
          .filter(_ => _)
      );
      alert("選択した生徒を休会に変更しました。");
      store.commit("END_LOADING");
    } catch (e) {
      alert("休会に変更中にエラーが発生しました。");
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to set students recess"
      );
      store.commit("END_LOADING");
      return;
    }
    this.$router.go(0);
  }

  async cancelStudentsRecess() {
    try {
      store.commit("START_LOADING", "更新中...");
      await Promise.all(
        this.selectedIds
          .map(id => {
            const student = this.students.find(
              student => student.ref.id === id
            );
            if (!student) return;
            return updateStudentRecess(student.ref, 0);
          })
          .filter(_ => _)
      );
      alert("選択した生徒の休会を解除しました。");
      store.commit("END_LOADING");
    } catch (e) {
      alert("休会を解除中にエラーが発生しました。");
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to cancel students recess"
      );
      store.commit("END_LOADING");
      return;
    }
    this.$router.go(0);
  }

  async deleteStudents() {
    try {
      store.commit("START_LOADING", "選択した生徒を削除中...");
      await Promise.all(
        this.selectedIds.map(id => {
          const student = this.students.find(student => student.ref.id === id);
          return student && deleteStudent(student.ref);
        })
      );
      alert("選択した生徒を削除しました。");
      store.commit("END_LOADING");
    } catch (e) {
      alert("削除処理中にエラーが発生しました。");
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete students"
      );
      store.commit("END_LOADING");
      return;
    }
    this.$router.go(0);
  }

  expandCustomProperties = (
    baseDatas: {
      [key: string]:
        | string
        | number
        | boolean
        | { id: string; value: string | number }[];
    }[]
  ) => {
    const data = baseDatas.map(_data => {
      const _properties = _data.properties
        ? [...(_data.properties as { id: string; value: string | number }[])]
        : [];
      if (_properties.length) {
        _properties.forEach(p => {
          _data[p.id] = p.value;
        });
      }
      return _data;
    });
    return data as TableData[];
  };

  async created() {
    const schoolId = this.$route.query.school;
    if (schoolId && typeof schoolId === "string") {
      this.selectedSchoolIds = [schoolId];
    }

    if (this.beforeLoad) {
      store.commit("studentList/SET_LEARNING_TERM", "過去7日間");
      this.loading = true;
      await Promise.all([
        store.dispatch("studentList/getStudentListDatas"),
        store.dispatch("studentList/getStatusList", {
          type: store.state.role?.data.type === "admin" ? "admin" : "tutor",
          tutorDocId:
            store.state.role?.data.type === "admin"
              ? ""
              : store.state.tutor?.main.ref.id
        })
      ]);
      this.loading = false;
    }

    this.datas = this.expandCustomProperties(this.baseDatas);

    const isServiceProvider = this.isServiceProvider ?? false;
    if (!this.isAdmin && isServiceProvider) {
      const childrenSchoolIds: string[] = this.schoolsForServieProvider
        ? this.schoolsForServieProvider.map(_ => _.ref.id)
        : [];

      this.datas.forEach(_studentList => {
        if (
          _studentList.schoolDocId &&
          childrenSchoolIds.some(_ => _ === _studentList.schoolDocId)
        )
          this.customProperties.forEach(c => {
            _studentList[c.id] = "- -";
          });
      });
    }

    this.email = getEmail();
    if (!localStorage[this.email]) {
      this.selectAllColumn();
    } else {
      const storedData = JSON.parse(localStorage[this.email]);
      if (storedData.unselectedColumnList) {
        this.selectedColumnList = this.columnList
          .filter(
            (column: CheckboxDataForColumnList) =>
              !storedData.unselectedColumnList.includes(column.id)
          )
          .map((column: CheckboxDataForColumnList) => column.id);
      } else {
        this.selectedColumnList = [...Array(this.columnList.length)].map(
          (_, i) => i
        );
      }
    }
    this.selectAllFilter();
  }
}
